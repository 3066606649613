import TetherComponent from "react-tether";

import {
  Icon,
  clsx,
  UIContext,
  SidebarState,
  Logo,
  Button,
  classNames,
} from "@alpacahq/ui";

import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import SidebarAccountItem, {
  SidebarAccountItemDivider,
  SidebarAccountItemProperties,
  SidebarAccountMenuItem,
} from "./SidebarAccountItem";
import CreatePaperAccountModal from "./CreatePaperAccountModal";
import { getPercentageChange } from "src/v2/utils";
import { useAccountContext } from "src/v2/providers/AccountProvider";
import { useGetMargin } from "src/v2/api/hooks";
import { useGetMargins } from "src/v2/api/hooks/useGetMargin";
import { Account, Margin, PaperAccount } from "src/v2/api/rest/account";
import { Path } from "../../path";
import useLocalStorage from "src/hooks/useLocalStorage";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

export interface SidebarAccountSwitcherProps {
  darkLogo?: boolean;
  ownerId?: string;
  product: string;
  dashboardTradeAccountMap?: Record<string, Margin>;
}

export const MAX_PAPER_ACCOUNTS = 3;

const getSidebarAccountItemProperties = (
  margins?: Margin[],
  type = "Paper",
  accounts?: PaperAccount[] | Account[],
  dashboardTradeAccountMap?: Record<string, Margin>
): SidebarAccountItemProperties[] => {
  const getName = (type: string, account?: PaperAccount | Account) => {
    if (type === "Paper") {
      return !!account?.name ? account.name : type;
    } else if (type === "Live") {
      const name = (account as Account)?.account_name;

      return !!name ? name : type;
    }

    return type;
  };

  return (
    margins?.map(({ equity, account_number, last_equity, id }, index) => {
      const dashboardMargin = dashboardTradeAccountMap?.[id];

      const displayedEquity = dashboardMargin?.equity ?? equity;
      const displayedLastEquity = dashboardMargin?.last_equity ?? last_equity;

      return {
        name: getName(type, accounts?.[index]),
        id,
        number: account_number,
        percentage: getPercentageChange(displayedLastEquity, displayedEquity),
        equity: displayedEquity,
        last_equity: displayedLastEquity,
      };
    }) ?? []
  );
};

// The dashboard uses redux state to display its equity value. While they use
// the same endpoints, equity changes by the second. To ensure the equity shown
// in dashboard and sidebar are the same, connect to redux until dashboard updated
const mapStateToProps = (state: { trade: Record<string, Margin> }) => ({
  dashboardTradeAccountMap: state.trade || {},
});

const SidebarAccountSwitcher: React.FC<SidebarAccountSwitcherProps> = ({
  darkLogo,
  product,
  dashboardTradeAccountMap,
}) => {
  const { sidebarState, setIsSidebarBlurred } = useContext(UIContext);
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpacity, setDropdownOpacity] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const {
    currentAccount,
    setCurrentAccount,
    liveAccounts,
    paperAccounts,
    paperAccountsNoDetail,
  } = useAccountContext();

  const [_savedAccountId, setSavedAccountId] = useLocalStorage("savedAccount");

  const liveAccount = liveAccounts[0];
  const isPaper = product === "paper";

  const { margin: liveMargin } = useGetMargin(
    "margin",
    liveAccount?.id,
    false,
    {
      enabled: !!liveAccount?.id,
    }
  );

  const { margin: paperMargins } = useGetMargins(
    "margin-paper",
    paperAccountsNoDetail.map(({ paper_account_id }) => paper_account_id),
    true,
    {
      enabled: !!paperAccountsNoDetail.length,
    }
  );

  const handleSwitchAccount = (newAccount: SidebarAccountItemProperties) => {
    const isAccountTypeDifferentFromProduct =
      (product === "live" &&
        paperAccounts.find((a) => a.id === newAccount.id)) ||
      (product === "paper" && liveAccounts.find((a) => a.id === newAccount.id));

    // Need to redirect if switching products, as invalid credentials error occur
    // if switching from paper to live and vice versa
    if (isAccountTypeDifferentFromProduct) {
      setSavedAccountId(newAccount.id);

      window.location.href = Path.format(Path.ROUTE_DASHBOARD, {
        product: isPaper ? "live" : "paper",
      });
      return;
    }

    const account =
      liveAccounts.find((a) => a.id === newAccount.id) ??
      paperAccounts.find((a) => a.id === newAccount.id);

    if (account) {
      setCurrentAccount(account);
    }
  };

  const liveAccountItems = getSidebarAccountItemProperties(
    liveMargin ? [liveMargin] : [],
    "Live",
    liveAccounts,
    dashboardTradeAccountMap
  );
  const paperAccountItems = getSidebarAccountItemProperties(
    paperMargins,
    "Paper",
    paperAccountsNoDetail,
    dashboardTradeAccountMap
  );

  const currentSidebarAccount = useMemo(() => {
    const account =
      liveAccountItems.find((a) => a.id === currentAccount?.id) ||
      paperAccountItems.find((pa) => pa.id === currentAccount?.id);
    return (
      account ??
      ({ name: "Guest", number: "no-account" } as SidebarAccountItemProperties)
    );
  }, [liveAccountItems, paperAccountItems, currentAccount]);

  // short-form for checking if the sidebar is collapsed
  const isCollapsed = sidebarState === SidebarState.COLLAPSED;

  useEffect(() => {
    // blur the sidebar when the dropdown is open to focus on the dropdown
    setIsSidebarBlurred(isOpen);
  }, [isOpen]);

  useEffect(() => {
    // close the dropdown when clicking outside of it
    // granted it may be a click on the dropdown itself, but
    // we want to close it anyway when you click an item
    const handleClickOutside = () => setIsOpen(false);
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // due to positioning issues with the dropdown, we need to force a re-render after a delay
  // the dropdown will not get cut-off from parent scroll containers this way
  const toggleOpen = (event: any) => {
    event.stopPropagation();

    setIsOpen(!isOpen);
    setDropdownOpacity(0);

    if (!isOpen) {
      setTimeout(() => {
        setForceUpdate(true);
        setDropdownOpacity(1);
      }, 100);
    }
  };

  const handleUpdate = useCallback(() => {
    // force a re-render after the first update
    if (forceUpdate) {
      setForceUpdate(false);
    }
  }, [forceUpdate]);

  return (
    <>
      <TetherComponent
        // @ts-ignore for some reason the types are not correct
        attachment="top left"
        // @ts-ignore for some reason the types are not correct
        targetAttachment="bottom left"
        // @ts-ignore for some reason the types are not correct
        offset="-12px 0"
        onUpdate={handleUpdate}
        renderTarget={(ref) => (
          <Button
            variant="ghost"
            ref={ref}
            onClick={toggleOpen}
            className={clsx(
              // base styles
              "flex w-full overflow-hidden cursor-pointer rounded-lg items-center space-x-1 h-10 px-0 py-0",
              // collapsed styles
              !isCollapsed ? "justify-between" : "justify-center bg-transparent"
            )}
          >
            <div className="flex w-full overflow-hidden items-center space-x-2">
              <Logo
                className={classNames("h-9 w-9", darkLogo && "text-gray-900")}
              />
              {!isCollapsed && (
                <div className="w-full overflow-hidden text-left text-base">
                  <div className="font-semibold text-gray-900 whitespace-nowrap truncate">
                    {currentSidebarAccount?.name}
                  </div>
                  <div className="font-medium text-gray-500">
                    {currentSidebarAccount?.number}
                  </div>
                </div>
              )}
            </div>
            {!isCollapsed && <Icon name="ChevronUpDown" />}
          </Button>
        )}
        renderElement={(ref) =>
          isOpen && (
            <div
              ref={ref}
              style={{ opacity: dropdownOpacity }}
              className="z-50 w-[275px] rounded-md border border-gray-200 bg-white p-4 space-y-2"
            >
              <SidebarAccountItemDivider label="Individual Trading" />
              {liveAccountItems.map((account) => (
                <SidebarAccountItem
                  {...account}
                  active={account.id === currentAccount?.id}
                  onClick={() => handleSwitchAccount(account)}
                />
              ))}

              <SidebarAccountItemDivider label="Paper" />
              {paperAccountItems.length > 0 ? (
                <div>
                  {paperAccountItems.map((account) => (
                    <SidebarAccountItem
                      {...account}
                      active={account.id === currentAccount?.id}
                      onClick={() => handleSwitchAccount(account)}
                    />
                  ))}
                </div>
              ) : (
                <SidebarAccountMenuItem>
                  No Paper Accounts
                </SidebarAccountMenuItem>
              )}

              <SidebarAccountItemDivider label="Account Management" />
              {paperAccounts.length < MAX_PAPER_ACCOUNTS && (
                <SidebarAccountMenuItem
                  onClick={() => setIsCreationModalOpen(true)}
                >
                  Open New Paper Account
                </SidebarAccountMenuItem>
              )}
              <SidebarAccountMenuItem
                onClick={() =>
                  history.push(
                    Path.format(Path.ROUTE_ALPACA_PROFILE, {
                      tab: "manage-accounts",
                    })
                  )
                }
              >
                Account Settings
              </SidebarAccountMenuItem>
            </div>
          )
        }
      />
      {isCreationModalOpen && (
        <CreatePaperAccountModal
          isOpen
          onClose={() => setIsCreationModalOpen(false)}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps)(SidebarAccountSwitcher);

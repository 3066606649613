import React, { useContext, useMemo } from "react";

import SidebarAccountSwitcher, {
  SidebarAccountSwitcherProps,
} from "./SidebarAccountSwitcher";

import { SidebarState, Text, UIContext, clsx, getHashCode } from "@alpacahq/ui";
import { ConditionalSidebarItemProps, SidebarItem } from "./SidebarItem";
import { SidebarSettings, SidebarSettingsProps } from "./SidebarSettings";
import { SidebarToggle } from "./SidebarToggle";

export interface SidebarProps
  extends SidebarAccountSwitcherProps,
    SidebarSettingsProps {
  items: ConditionalSidebarItemProps[];
  darkLogo?: boolean;
  version?: string;
  children?: React.ReactNode;
  ownerId?: string;
  product: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  items,
  darkLogo,
  version,
  settings,
  children,
  ownerId,
  product,
}) => {
  const { sidebarState, isSidebarBlurred } = useContext(UIContext);

  const sidebarItems = useMemo(() => {
    return (
      items
        .filter(Boolean)
        // @ts-ignore
        .map((item) => <SidebarItem key={getHashCode(item)} {...item} />)
    );
  }, [items]);

  return (
    // this is the sidebar container
    <div className="overflow-y-auto h-screen flex-shrink-0">
      <nav
        className={clsx(
          // base styles for the sidebar itself
          "flex flex-col space-y-6 rounded-lg h-full p-6",
          // when you click on a sidebar drop menu, the sidebar is "blurred"
          isSidebarBlurred && "pointer-events-none",
          // the sidebar is hidden when the user is on mobile
          sidebarState === SidebarState.HIDDEN && "hidden",
          // the sidebar is expanded when the user is on desktop otherwise it's collapsed (w-fit)
          sidebarState === SidebarState.EXPANDED ? "w-64" : "w-fit",
          // on mobile we want full height and width
          sidebarState === SidebarState.MOBILE &&
            "fixed top-0 left-0 z-50 h-full w-full bg-white overflow-y-scroll rounded-none border-transparent"
        )}
      >
        <SidebarAccountSwitcher
          darkLogo={darkLogo}
          ownerId={ownerId}
          product={product}
        />
        <div
          className={clsx(
            // base styles for the sidebar items
            "flex flex-col space-y-0",
            // if collapsed, we want the sidebar items to be centered
            sidebarState === SidebarState.COLLAPSED && "items-center"
          )}
        >
          {sidebarItems}
        </div>
        {children}
        <div className="flex w-full flex-1 items-end">
          <div
            className={clsx(
              // base styles for the sidebar tools
              "flex w-full justify-between",
              // if expanded or mobile, we want the tools to be spaced out
              [SidebarState.EXPANDED, SidebarState.MOBILE].includes(
                sidebarState
              )
                ? "space-x-4"
                : "flex-col items-center space-y-2.5"
            )}
          >
            <SidebarSettings settings={settings} />
            <SidebarToggle />
          </div>
        </div>
        {
          // show the app version when the sidebar is not collapsed
          version && sidebarState === SidebarState.EXPANDED && (
            <Text className="mx-auto text-center text-base font-medium text-gray-500">
              {version}
            </Text>
          )
        }
      </nav>
    </div>
  );
};
export default Sidebar;

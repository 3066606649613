import moment from "moment";
import instance from "..";

import { renameFile } from "../../utils";
import { AuthService, getAttributes, getCustomAttr } from "../auth";
import { uniqBy } from "lodash";

export interface TradeAccount {
  id: string;
  account_number: string;
  status: string;
  crypto_status: string;
  currency: string;
  buying_power: string;
  regt_buying_power: string;
  daytrading_buying_power: string;
  effective_buying_power: string;
  non_marginable_buying_power: string;
  bod_dtbp: string;
  cash: string;
  cash_withdrawable: string;
  cash_transferable: string;
  accrued_fees: string;
  pending_transfer_out: string;
  pending_transfer_in: string;
  portfolio_value: string;
  pattern_day_trader: boolean;
  trading_blocked: boolean;
  transfers_blocked: boolean;
  account_blocked: boolean;
  created_at: Date;
  trade_suspended_by_user: boolean;
  multiplier: string;
  shorting_enabled: boolean;
  equity: string;
  last_equity: string;
  long_market_value: string;
  short_market_value: string;
  position_market_value: string;
  initial_margin: string;
  maintenance_margin: string;
  last_maintenance_margin: string;
  sma: string;
  daytrade_count: number;
  balance_asof: string;
  clearing_broker: string;
}

export interface UserConfigurations {
  max_margin_multiplier: string;
  dtbp_check: string;
  no_shorting: boolean;
  suspend_trade: boolean;
  fractional_trading: boolean;
  pdt_check: string;
  trade_confirm_email: string;
  trade_suspended_by_user: boolean;
  ptp_no_exception_entry: boolean;
  max_options_trading_level?: number;
}

export type EliteStatus = null | "30k" | "100k";

export interface AccountCashInterest {
  currency?: string;
  status?: string;
  apr_tier_name?: string | null | undefined;
}

export interface Account {
  account_number: string;
  account_name?: string;
  admin_configurations: {
    allow_instant_ach: boolean;
    max_options_trading_level?: number;
  };
  balance_asof: string;
  can_limited_fund: boolean;
  capabilities?: {
    bank_linkable_status?: "disallowed" | "allowed";
    depositable_status?: "disallowed" | "limited" | "allowed";
    withdrawable_status?: "disallowed" | "limited" | "allowed";
  };
  clearing_broker: string;
  correspondent: string;
  created_at: string;
  crypto_broker: string;
  crypto_status: string;
  crypto_tier: number;
  day_trade_count: number;
  email: string;
  elite_status: EliteStatus;
  equity: string;
  id: string;
  is_legal_entity: boolean;
  kyc_provider: string;
  last_onboarding_step: string;
  maintenance_margin: string;
  marked_pattern_day_trader_at: string;
  marked_risky_transfers_at: string;
  multiplier: string;
  name: string;
  onfido_applicant_id?: string;
  onfido_details?: Record<string, any>;
  options_approved_level: number;
  options_trading_level: number;
  shorting_enabled: string;
  status: string;
  user_configurations: UserConfigurations;
  pattern_day_trader: boolean;
  cash_interest?: AccountCashInterest[];
  currency: string;
}

export type Portfolio = {
  [key: string]: {
    // date, profit_loss, profit_loss_pct, portfolio_value
    arrays: [[], [], [], []];
  };
};
export interface Position {
  asset_id: string;
  symbol: string;
  exchange: string;
  asset_class: string;
  asset_marginable: boolean;
  qty: string;
  avg_entry_price: string;
  side: string;
  market_value: string;
  cost_basis: string;
  unrealized_pl: string;
  unrealized_plpc: string;
  unrealized_intraday_pl: string;
  unrealized_intraday_plpc: string;
  current_price: string;
  lastday_price: string;
  change_today: string;
  qty_available: string;
}

export interface OwnerDetails {
  permanent_resident: boolean;
  annual_income_max: number;
  annual_income_min: number;
  liquid_net_worth: number;
  total_net_worth: number;
  city: string;
  unit: string;
  country_of_citizenship: string;
  country_of_tax_residence: string;
  created_at: string;
  crypto_agreement_signed_at?: Date | null;
  crypto_agreement_revision?: string;
  customer_agreement_signed_at?: Date;
  opra_agreement_signed_at?: Date;
  date_of_birth: string;
  employer_address: string;
  employer: string;
  employment_status: string;
  country_of_birth: string;
  visa_type: string;
  visa_expiration_date?: Date;
  family_name: string;
  function: string;
  funding_source: string[];
  given_name: string;
  immediate_family_exposed: boolean;
  is_affiliated_exchange_or_finra: null;
  is_control_person: boolean;
  is_discretionary: boolean;
  is_politically_exposed: boolean;
  is_professional: boolean;
  legal_name: string;
  liquid_net_worth_max: number;
  liquid_net_worth_min: number;
  owner_id: string;
  phone_number: string;
  position: string;
  postal_code: string;
  state: string;
  street_address: string[];
  tax_id_type: string;
  total_net_worth_max: number;
  total_net_worth_min: number;
  investment_experience_with_options?: string;
  investment_experience_with_stocks?: string;
  investment_objective?: string;
  investment_time_horizon?: string;
  risk_tolerance?: string;
  liquidity_needs?: string;
  esign_audit?: {
    agreement: string;
    signed_at: string;
    ip_address: string;
    revision: string;
  }[];
  cash_interest: AccountCashInterest[];
}

export type Owner = {
  owner_id: string;
  is_ubo: boolean;
  is_authorized_individual: boolean;
  is_legal_entity: boolean;
  is_legal_account_opener: boolean;
  email: string | null;
  given_name: string | null;
  middle_name: string | null;
  family_name: string | null;
  date_of_birth: string | null;
  phone_number: string | null;
  country_of_tax_residence: string | null;
  country_of_citizenship: string | null;
  street_address: string | null;
  unit: string | null;
  city: string | null;
  state: string | null;
  postal_code: string | null;
  tax_id_type: string | null;
  title_at_company: string | null;
  is_politically_exposed: boolean | null;
  immediate_family_exposed: boolean;
  is_control_person: boolean | null;
  controlling_firms: string | null;
  is_affiliated_exchange_or_finra: boolean | null;
  affiliated_firm: string | null;
  legal_name: string | null;
  country_of_incorporation: string | null;
  state_of_incorporation: string | null;
  date_of_incorporation: string | null;
  entity_type: string | null;
  entity_type_other_description: string | null;
  is_participating_fatca: boolean | null;
  is_exempt_from_tax_under_501a: boolean | null;
  business_registration_number: string | null;
  entity_country: string | null;
  mailing_street_address: string | null;
  mailing_unit: string | null;
  mailing_city: string | null;
  mailing_state: string | null;
  mailing_postal_code: string | null;
  mailing_country: string | null;
  annual_income_min: number | null;
  annual_income_max: number | null;
  total_net_worth_min: number | null;
  total_net_worth_max: number | null;
  liquid_net_worth_min: number | null;
  liquid_net_worth_max: number | null;
  intended_use_of_account: string | null;
  funding_source: string | null;
  type_of_business: string | null;
  type_of_business_other_description: string | null;
  percentage_ownership: number | null;
};

export interface TrustedContact {
  account_id: string;
  email_address: string;
  phone_number: string;
  street_address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  given_name: string;
  family_name: string;
}

export type DocUploadData = {
  doc_type: string;
  doc_sub_type: string;
  content: any;
};

export interface GetAccountParams {
  product: string;
}

export const getAccount = async ({
  product = "live",
}: GetAccountParams): Promise<Account> => {
  // fetch the live account no matter what
  const accounts = await instance.get<Account[]>("/accounts");
  const live = accounts.data[0];

  // if not paper we can stop here and return the live account
  if (product !== "paper") {
    return live;
  }

  // if paper we need to fetch the paper account
  const paperAccounts = await getPaperAccounts();
  const paper = await instance.get<Account>(
    "/paper_accounts/"
      .concat(paperAccounts[0].paper_account_id)
      .concat("/trade_account/margin")
  );

  return paper.data;
};

export const getLiveAccounts = async () => {
  const accounts = await instance.get<Account[]>("/accounts");
  return accounts.data;
};

export const getDetails = async (
  accountID: string,
  product = "live"
): Promise<OwnerDetails> => {
  const res = await instance.get<OwnerDetails>(
    product === "paper" ? "/paper_accounts/" : `/accounts/${accountID}/details`
  );

  return res.data;
};

export const patchDetails = async (
  accountID: string,
  payload: Partial<OwnerDetails>
) => {
  const res = await instance.patch<OwnerDetails>(
    `/accounts/${accountID}/details`,
    payload
  );
  return res.data;
};

export type PatchAccountRequest = {
  is_legal_entity: boolean;
  account_name: string;
  cash_interest: AccountCashInterest[];
};

export const patchAccount = async (
  accountID: string,
  payload: Partial<PatchAccountRequest>
) => {
  const res = await instance.patch<OwnerDetails>(
    `/accounts/${accountID}`,
    payload
  );
  return res.data;
};

export const patchEsign = async (
  accountID: string,
  payload: { agreement: string; revision: string; signed_at: Date }
) => {
  const res = await instance.patch<OwnerDetails>(
    `/accounts/${accountID}/details/esign`,
    { esign_audit: [payload] }
  );
  return res.data;
};

export const getAccountOpenerDetails = async (
  accountID: string
): Promise<OwnerDetails> => {
  const res = await instance.get<{ owners: OwnerDetails[] }>(
    `/accounts/${accountID}/owners`
  );
  return res.data.owners.find(
    // @ts-ignore
    (owner) => owner.is_legal_account_opener
  ) as OwnerDetails;
};

export const getOwners = async (accountID: string): Promise<Owner[]> => {
  const res = await instance.get<{ owners: Owner[] }>(
    `/accounts/${accountID}/owners`
  );
  return res.data.owners;
};

export const setUserConfigurations = async (
  accountID: string,
  product = "live",
  payload: Partial<UserConfigurations>
) => {
  const res = await instance.patch(
    `/${getPathForProduct(product)}/${accountID}/configurations`,
    payload
  );

  return res.data;
};

export interface UserConfigurationsParams {
  accountID: string;
  product?: string;
}

export const getUserConfigurations = async ({
  accountID = "",
  product = "live",
}: UserConfigurationsParams): Promise<UserConfigurations> => {
  const res = await instance.get<UserConfigurations>(
    `/${getPathForProduct(product)}/${accountID}/configurations`
  );

  return res.data;
};

export const patchFinancialProfile = async (
  accountID: string,
  payload: Partial<OwnerDetails>
) => {
  const res = await instance.patch<OwnerDetails>(
    `/accounts/${accountID}/steps/next/financial_profile_step`,
    payload
  );
  return res.data;
};

export const patchEmploymentStatus = async (
  accountID: string,
  payload: Partial<OwnerDetails>
) => {
  const res = await instance.patch<OwnerDetails>(
    `/accounts/${accountID}/steps/next/employment_step`,
    payload
  );
  return res.data;
};

export const getTrustedContact = async (
  accountID: string
): Promise<TrustedContact> => {
  const res = await instance.get(`/accounts/${accountID}/trusted_contact`);
  return res.data;
};

export type Product = "live" | "paper";

export interface LiquidatePositionParams {
  accountID: string;
  product: Product;
  symbol: string;
  cancelOrders?: boolean;
}

export const liquidatePosition = async ({
  product,
  accountID,
  symbol,
  cancelOrders,
}: LiquidatePositionParams) => {
  if (symbol) {
    const res = await instance.delete(
      `/${
        product === "live" ? "accounts" : "paper_accounts"
      }/${accountID}/positions/${symbol}?cancel_orders=${cancelOrders}`,
      {
        data: {
          allow_out_of_markets: false,
          symbols: [symbol],
        },
      }
    );

    return res.data;
  }
};

export type ExcerciseOptionParams = {
  accountID: string;
  symbol: string;
  product: string;
};

export const exerciseOption = async ({
  accountID,
  symbol,
  product,
}: ExcerciseOptionParams) => {
  const res = await instance.post(
    `/${
      product === "live" ? "accounts" : "paper_accounts"
    }/${accountID}/positions/${symbol}/exercise`
  );

  return res.data;
};

export type OrderLeg = Omit<Order, "legs">;

export interface Order {
  id: string;
  client_order_id: string;
  created_at: Date;
  updated_at: Date;
  submitted_at: Date;
  filled_at: Date;
  expired_at?: any;
  canceled_at?: any;
  failed_at?: any;
  replaced_at?: any;
  replaced_by?: any;
  replaces?: any;
  expires_at?: any;
  asset_id: string;
  symbol: string;
  asset_class: string;
  notional?: any;
  qty: string;
  filled_qty: string;
  ratio_qty?: string;
  filled_avg_price: string;
  order_class: string;
  order_type: string;
  type: string;
  side: string;
  position_intent: string;
  time_in_force: string;
  limit_price?: any;
  stop_price?: any;
  status: string;
  extended_hours: boolean;
  legs?: OrderLeg[];
  trail_percent?: any;
  trail_price?: any;
  hwm?: any;
  subtag?: any;
  source: string;
}

export interface CancelOrderParams {
  accountID: string;
  product: string;
  orderID: string;
}

export const cancelOrder = async ({
  product,
  accountID,
  orderID,
}: CancelOrderParams) => {
  const res = await instance.delete(
    `/${
      product === "live" ? "accounts" : "paper_accounts"
    }/${accountID}/orders/${orderID}`
  );

  return res.data;
};

export const patchTrustedAccount = async (
  accountID: string,
  payload: Partial<TrustedContact>
) => {
  const res = await instance.patch<TrustedContact>(
    `/accounts/${accountID}/trusted_contact`,
    {
      ...payload,
      // todo: fix this, i'll get to it later
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    }
  );
  return res.data;
};

export const getTrustedAccount = async (
  accountID: string
): Promise<TrustedContact> => {
  const res = await instance
    .get<TrustedContact>(`/accounts/${accountID}/trusted_contact`)
    .catch(() => null);

  // todo: handle this better later, I don't have time to do it now
  return res?.data || ({} as TrustedContact);
};

export const postTrustedAccount = async (
  accountID: string,
  payload: Partial<TrustedContact>
) => {
  const res = await instance.post<TrustedContact>(
    `/accounts/${accountID}/trusted_contact`,
    {
      ...payload,
      // todo: fix this, i'll get to it later
      street_address: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    }
  );
  return res.data;
};

export interface NonIdentityAccountDocumentURL {
  id: string;
  url: string;
}

export const getNonIdentityAccountDocumentURL = async (
  accountID: string,
  documentID: string
): Promise<NonIdentityAccountDocumentURL> => {
  const res = await instance.get(
    `accounts/${accountID}/documents/${documentID}/url`,
    {
      headers: {
        Accept: "*/*",
      },
    }
  );

  return res.data;
};

export interface NonIdentityAccountDocument {
  id: string;
  name: string;
  type: string;
  sub_type: string;
  date: string;
}

const DEFAULT_DOCUMENTS_RANGE_IN_YEARS = 3;

export const getNonIdentityAccountDocuments = async (
  accountID: string
): Promise<Array<NonIdentityAccountDocument>> => {
  const res = await instance.get(
    `accounts/${accountID}/documents`.concat(
      `?start=${moment()
        .subtract(DEFAULT_DOCUMENTS_RANGE_IN_YEARS, "year")
        .format("YYYY-MM-DD")}&end=${moment().format("YYYY-MM-DD")}`
    )
  );
  return res.data;
};

export const getAccountDocuments = async (
  accountID: string
): Promise<Array<DocUploadData>> => {
  const res = await instance.get(`documents/accounts/${accountID}`);
  return res.data;
};

export const uploadAccountDocument = async (
  accountID: string,
  payload: DocUploadData
): Promise<DocUploadData> => {
  var formData = new FormData();
  formData.append("uploadfile", payload.content);
  const res = await instance.post(
    `documents/accounts/${accountID}/upload/${payload.doc_type}/${payload.doc_sub_type}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return res.data;
};

export const fetchAccountAgreement = async (
  accountID: string,
  agreement: string
): Promise<Blob> => {
  const res = await instance.get(
    `/accounts/${accountID}/taxfiles/${agreement}/preview`,
    { responseType: "blob" }
  );
  return res.data;
};

export const requestEmail = async (
  email: string,
  a: string
): Promise<unknown> => {
  const res = await instance.post("/owner/email", { email, a });
  return res.data;
};

export const confirmEmail = async (code: string): Promise<unknown> => {
  const res = await instance.post("/owner/email-confirm", { code });
  return res.data;
};

export const getCountryOfCitizenship = async () => {
  const user = await AuthService.getCurrentUser();
  const attr = await getAttributes(user);
  return getCustomAttr(attr, "citizenship", "");
};

// adding a symbol to the watchlist
// POST https://staging-app.tradetalk.us/internal/accounts/b501fb34-56cd-4a2a-80cd-a9a37bf16e10/watchlists:by_name?name=Primary Watchlist
// {"symbol":"GME"}

// removing a symbol from a watchlist
// PUT https://staging-app.tradetalk.us/internal/accounts/b501fb34-56cd-4a2a-80cd-a9a37bf16e10/watchlists:by_name?name=Primary Watchlist
// {"name":"Primary Watchlist","symbols":["SPY","FB"]}

export interface Asset {
  id: string;
  class: string;
  exchange: string;
  symbol: string;
  name: string;
  status: string;
  tradable: boolean;
  marginable: boolean;
  maintenance_margin_requirement: number;
  shortable: boolean;
  easy_to_borrow: boolean;
  fractionable: boolean;
}

export interface Watchlist {
  id: string;
  account_id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  assets: Asset[];
}

export interface GetWatchlistParams {
  accountID: string;
  name: string;
}

export const getWatchlist = async ({
  accountID,
  name,
}: GetWatchlistParams): Promise<Watchlist> => {
  const res = await instance.get(
    `/accounts/${accountID}/watchlists:by_name?name=${name}`
  );
  return res.data;
};

export interface AddSymbolToWatchlistParams {
  accountID: string;
  name: string;
  symbol: string;
}

export const addSymbolToWatchlist = async ({
  accountID,
  name,
  symbol,
}: AddSymbolToWatchlistParams): Promise<Watchlist> => {
  const res = await instance.post(
    `/accounts/${accountID}/watchlists:by_name?name=${name}`,
    { symbol }
  );
  return res.data;
};

export interface RemoveSymbolFromWatchlistParams {
  accountID: string;
  name: string;
  symbols: string[];
}

export const removeSymbolFromWatchlist = async ({
  accountID,
  name,
  symbols,
}: RemoveSymbolFromWatchlistParams): Promise<Watchlist> => {
  const res = await instance.put(
    `/accounts/${accountID}/watchlists:by_name?name=${name}`,
    { name, symbols }
  );
  return res.data;
};

export interface CreateWatchlistParams {
  accountID: string;
  name: string;
  symbols: string[];
}

export const createWatchlist = async ({
  accountID,
  name,
  symbols,
}: CreateWatchlistParams): Promise<Watchlist> => {
  const res = await instance.post(`/accounts/${accountID}/watchlists`, {
    name,
    symbols,
  });
  return res.data;
};

export interface GetWatchlistsParams {
  accountID: string;
}

export const getWatchlists = async ({
  accountID,
}: GetWatchlistsParams): Promise<Watchlist[]> => {
  const res = await instance.get(`/accounts/${accountID}/watchlists`);
  return res.data;
};

export interface ResetPaperAccountParams {
  accountID: string;
  paperAccountID: string;
  cash: number;
}

export const resetPaperAccount = async ({
  accountID,
  paperAccountID,
  cash,
}: ResetPaperAccountParams): Promise<void> => {
  const res = await instance.post(
    `/accounts/${accountID}/paper_accounts/${paperAccountID}`,
    { cash }
  );

  return res.data;
};

export interface PaperAccount {
  owner_id: string;
  paper_account_id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export const getPaperAccounts = async (): Promise<PaperAccount[]> => {
  const res = await instance.get(`/paper_accounts`);
  return res.data;
};

export const getPaperAccountsWithDetails = async (
  paperAccountIds?: string[]
): Promise<Account[]> => {
  if (!paperAccountIds?.length) {
    return [];
  }

  const res = await Promise.all(
    paperAccountIds.map((id) =>
      instance.get(`/paper_accounts/${id}/trade_account/margin`)
    )
  );

  return res.map((r) => r.data);
};

export type createPaperAccountParams = {
  name: string;
  cash: number;
};

export const createPaperAccount = async (params: createPaperAccountParams) => {
  const res = await instance.post(`/paper_accounts`, params);
  return res.data;
};

type patchPaperAccountParams = {
  name: string;
};

export const editPaperAccount = async (
  paperAccountId: string,
  params: patchPaperAccountParams
) => {
  const res = await instance.patch(`/paper_accounts/${paperAccountId}`, params);
  return res.data;
};

export const deletePaperAccount = async (paperAccountId: string) => {
  const res = await instance.delete(`/paper_accounts/${paperAccountId}`);
  return res.data;
};

export interface Margin {
  id: string;
  crypto_tier: number;
  account_number: string;
  status: string;
  crypto_status: string;
  currency: string;
  held_ach_deposits: number;
  buying_power: string;
  regt_buying_power: string;
  daytrading_buying_power: string;
  effective_buying_power: string;
  non_marginable_buying_power: string;
  bod_dtbp: string;
  cash: string;
  cash_withdrawable: string;
  cash_transferable: string;
  accrued_fees: string;
  pending_transfer_out: string;
  pending_transfer_in: string;
  portfolio_value: string;
  pattern_day_trader: boolean;
  trading_blocked: false;
  transfers_blocked: false;
  account_blocked: false;
  created_at: string;
  trade_suspended_by_user: false;
  multiplier: string;
  shorting_enabled: boolean;
  equity: string;
  last_equity: string;
  long_market_value: string;
  short_market_value: string;
  position_market_value: string;
  initial_margin: string;
  maintenance_margin: string;
  last_maintenance_margin: string;
  sma: string;
  daytrade_count: number;
  balance_asof: string;
  clearing_broker: string;
}

export const getMargin = async (
  accountID: string,
  paper?: boolean,
  last?: boolean
): Promise<Margin> => {
  const startPath = paper ? "/paper_accounts" : "/accounts";
  const endPath = last ? "/last" : "";
  const res = await instance.get(
    `${startPath}/${accountID}/trade_account/margin${endPath}`
  );

  if (last) {
    // @todo remove mutations when endpoints are refactored
    res.data.last_effective_buying_power =
      res.data.last_buying_power - res.data.last_maintenance_margin;
    res.data.last_excess_margin =
      res.data.last_equity - res.data.last_maintenance_margin;
  }

  return res.data;
};

export interface GetOrdersParams {
  accountID: string;
  paper?: boolean;
  symbols?: string[];
  status?: "open" | "closed" | "all";
  side?: "buy" | "sell" | "sell_short" | "";
  limit?: number;
}

export const getOrders = async ({
  accountID,
  paper,
  symbols,
  status = "all",
  side = "",
  limit = 3000,
}: GetOrdersParams): Promise<Order[]> => {
  const ORDER_LIMIT = Math.min(3000, limit);
  const LIMIT = Math.min(500, ORDER_LIMIT);
  const REQUEST_LIMIT = 10;

  let allOrders: Order[] = [];
  let fetchDate = moment().add(1, "day").toISOString();
  let fetching = true;
  let requestCount = 0;

  while (fetching) {
    const res = await instance.get(
      `/${
        paper ? "paper_accounts" : "accounts"
      }/${accountID}/orders?status=${status}&side=${side}&symbols=${symbols?.join(
        ","
      )}&limit=${LIMIT}&until=${fetchDate}`
    );

    const orders = res.data ?? [];
    allOrders = [...allOrders, ...orders];
    requestCount++;

    // Prevent user from stuck on request loop
    if (
      orders.length === LIMIT &&
      allOrders.length < ORDER_LIMIT &&
      requestCount < REQUEST_LIMIT
    ) {
      const earliestSubmittedOrderDate = orders.sort((a: Order, b: Order) => {
        return (
          moment(a.submitted_at).valueOf() - moment(b.submitted_at).valueOf()
        );
      })?.[0]?.submitted_at;

      // Fail safe incase for some reason fetchDate is not updating we don't want
      // the app to stuck on looping
      if (!moment(earliestSubmittedOrderDate).isSame(fetchDate)) {
        fetchDate = moment(earliestSubmittedOrderDate).toISOString();
      } else {
        fetching = false;
      }
    } else {
      fetching = false;
    }
  }

  return uniqBy(allOrders, "id");
};

export interface GetPositionParams {
  accountID: string;
  symbol: string;
  paper?: boolean;
}

export const getPosition = async ({
  accountID,
  symbol,
  paper,
}: GetPositionParams): Promise<Position> => {
  const res = await instance.get(
    `/${paper ? "paper_accounts" : "accounts"}/${accountID}/positions/${symbol}`
  );
  return res.data;
};

export type OptionContract = {
  id: string;
  symbol: string;
  name: string;
  status: "active" | "inactive";
  tradable: boolean;
  expiration_date: string;
  root_symbol: string;
  underlying_symbol: string;
  underlying_asset_id: string;
  type: "call" | "put";
  style: "american";
  strike_price: string;
  size: string;
  open_interest: string;
  open_interest_date: string;
  close_price: string;
  close_price_date: string;
};

export interface GetPositionsParams {
  accountID: string;
  product?: string;
}

export const getPositions = async ({
  accountID,
  product = "paper",
}: GetPositionsParams): Promise<Position[]> => {
  const res = await instance.get(
    `/${
      product === "paper" ? "paper_accounts" : "accounts"
    }/${accountID}/positions`
  );

  return res.data;
};

export interface Activity {
  id: string;
  activity_type: string;
  date: string;
  transaction_time: string;
  net_amount: string;
  description: string;
  symbol: string;
  side: string;
  price: string;
  qty: string;
  per_share_amount: string;
  status: string;
}

export interface GetActivitiesParams {
  accountID: string;
  paper?: boolean;
  activityTypes?: string[];
  after?: string;
  date?: string;
  until?: string;
  page_size?: number;
  page_token?: string;
}

export const getActivities = async ({
  accountID,
  paper,
  activityTypes,
  after,
  date,
  until,
  page_size,
  page_token,
}: GetActivitiesParams): Promise<Activity[]> => {
  const base = paper ? "/paper_accounts" : "/accounts";
  const queryParams = {
    activity_types: activityTypes?.join(","),
    after,
    date,
    until,
    page_size,
    page_token,
  };

  const res = await instance.get(`${base}/${accountID}/activities`, {
    params: queryParams,
  });

  return res.data;
};

export interface Bank {
  id: string;
  account_id: string;
  name: string;
  status: string;
  country: string;
  state_province: string;
  postal_code: string;
  city: string;
  street_address: string;
  account_number: string;
  bank_code: string;
  bank_code_type: string;
  created_at: Date;
  updated_at: Date;
}

export interface GetBanksParams {
  accountID: string;
}

export const getBanks = async ({
  accountID,
}: GetBanksParams): Promise<Bank[]> => {
  const res = await instance.get(`/accounts/${accountID}/banks`);
  return res.data;
};

export interface CreateBankParams {
  accountID: string;
  bank: Bank;
}

export const createBank = async ({
  accountID,
  bank,
}: CreateBankParams): Promise<Bank> => {
  const res = await instance.post(`/accounts/${accountID}/banks`, bank);
  return res.data;
};

export const uploadBankStatement = async ({
  accountID,
  file,
}: {
  accountID: string;
  file: File;
}): Promise<any> => {
  const now = moment().format("YYYY-MM-DD HH:mm:ss");
  const bankStatement = renameFile(file, `bank_statement_${now}`);
  const formData = new FormData();
  formData.append("uploadfile", bankStatement);

  const res = await instance.post(
    `/documents/accounts/${accountID}/upload/identity_verification/bank_statement`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return res.data;
};

export interface DeleteBankParams {
  accountID: string;
  bankID: string;
}

export const deleteBank = async ({
  accountID,
  bankID,
}: DeleteBankParams): Promise<void> => {
  const res = await instance.delete(`/accounts/${accountID}/banks/${bankID}`);
  return res.data;
};

export interface AccessKey {
  id: string;
  account_id: string;
  secret: string;
  status: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface GetAccessKeysParams {
  accountID?: string;
}

export const getAccessKeys = async ({
  accountID,
}: GetAccessKeysParams = {}): Promise<AccessKey[]> => {
  const res = await instance.get(
    !!accountID ? `/paper_accounts/${accountID}/access_keys` : `/access_keys`
  );

  return res.data;
};

export interface DeleteAccessKeyParams {
  accessKeyID: string;
  accountID?: string;
}

export const deleteAccessKey = async ({
  accountID,
  accessKeyID,
}: DeleteAccessKeyParams): Promise<AccessKey> => {
  const res = await instance.delete(
    !!accountID
      ? `/paper_accounts/${accountID}/access_keys/${accessKeyID}`
      : `/access_keys/${accessKeyID}`
  );

  return res.data;
};

export interface CreateNewAccessKeyParams {
  accountID?: string;
}

export const createNewAccessKey = async ({
  accountID,
}: CreateNewAccessKeyParams = {}): Promise<AccessKey> => {
  const res = await instance.post(
    !!accountID ? `/paper_accounts/${accountID}/access_keys` : `/access_keys`
  );

  return res.data;
};

const getPathForProduct = (product: string) => {
  return product === "paper" ? "paper_accounts" : "accounts";
};

export const getIntercomUserHash = async (): Promise<string> => {
  const res = await instance.get<{ intercom_id: string }>(`/owner/intercom-id`);

  return res.data.intercom_id;
};

type OptionsRequestStatus =
  | "PENDING"
  | "APPROVED"
  | "LOWER_LEVEL_APPROVED"
  | "REJECTED";

export type OptionsApprovalStatus = {
  OptionsRequestStatus: OptionsRequestStatus | null;
  ReapplyAvailableDate: string | null;
};

export const getOptionsApprovalStatus = async (
  accountId: string
): Promise<OptionsApprovalStatus> => {
  const res = await instance.get<OptionsApprovalStatus>(
    `/accounts/${accountId}/options/approval`
  );
  return res.data;
};

export type OptionsApprovalResponse = {
  id: string;
  account_id: string;
  created_at: string;
  updated_at: string;
  requested_level: number;
  status: OptionsRequestStatus;
};

export const postOptionsApproval = async (
  accountId: string,
  level: 1 | 2
): Promise<OptionsApprovalResponse> => {
  const res = await instance.post<OptionsApprovalResponse>(
    `/accounts/${accountId}/options/approval`,
    {
      level,
    }
  );
  return res.data;
};

export interface CIDR {
  cidr: string;
}

export interface IPAllowlist {
  enabled: boolean;
  cidrs: string[];
}

export const getIPAllowlist = async (
  accountID: string
): Promise<IPAllowlist> => {
  const res = await instance.get(`/accounts/${accountID}/allowed-cidrs`);

  return res.data;
};
